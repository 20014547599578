<template></template>

<script lang="ts" setup>
	import { onMounted } from "vue";
	import useIsDebug from "~/composables/useIsDebug";
	import { ArticleContentTypes, PageContentTypes } from "@living-online/types";
	import type { ContentTypes } from "@living-online/types";
	import { useShowAds } from "~/composables/useShowAds";
	import useTransmaticoSmartAds from "~/composables/useTransmaticoSmartAds";

	declare global {
		interface Window {
			googletag: any;
			yieldlove_cmd: any;
			YLHH: any;
			slider: any;
			subNavigation: any;
			ndiAdServing: any;
		}
	}

	const props = withDefaults(
		defineProps<{
			systemdata?: any;
			metadata?: any;
			categoryData?: any;
		}>(),
		{
			categoryData: {
				adRegion: "ueberregional",
				adCategory: "homepage",
			},
		},
	);

	const currentUrl = useRequestURL();
	let showAds = await useShowAds();
	let userType = useUserType(); // anonymous|registered|premium|internal aus userInfo cookie nach login

	// Abhängig vom Mandanten -- swp - ['BW', 'BY'], lr, moz - ["BB", "BRB"]
	let targeting_state = usePublication() === "swp" ? ["BW", "BY"] : ["BB", "BRB"];

	const { audience } = useMetadata(props.metadata);

	function getContentType(type: ContentTypes) {
		return ArticleContentTypes.indexOf(type) > -1 ? "article" : PageContentTypes.indexOf(type) > -1 ? "overview" : "other";
	}

	/**
	 * @author: j.hofmann@swp.de
	 * edited by roland.heuberger@unitb.de
	 *
	 * @description: Die Klasse setzt die auf der Seite vorhandenen Rahmendaten und nutzt diese um die Werbemittel beim AdServer anzufragen.
	 *
	 * Documentation: https://stroeerdigitalgroup.atlassian.net/wiki/spaces/SDPUBLIC/pages/25306264/Deutsche+MetaTag+Dokumentation
	 */

	onMounted(() => {
		const domainPlusTLD = useRuntimeConfig().public[usePublication() + "Domain"];
		let domain = "swp";
		if (typeof domainPlusTLD === "string") {
			const domainParts = domainPlusTLD.split(".");
			// Wir nehmen die Domain, den Teil vor der TLD
			domain = domainParts[domainParts.length - 2];
		}
		const ndiAdServing = {
			admanager_network_code: 22730136841,
			admanager_tenant_path: domain + ".de",
			adSlots: document.querySelectorAll(".ad-slot"),
			ad_zone: props.categoryData.adCategory,
			articleId: props.systemdata?.documentId,
			articleType: props.metadata?.payCategory || "free",
			audience: audience.value || "(not set)",
			breakpoints: [768], //Darunter werden die mobilen AdSlots angefragt, darüber die Desktop-Varianten
			category: getContentType(props.systemdata?.contentType),
			clientWidth: document.documentElement.clientWidth || document.body.clientWidth || window.innerWidth,
			contentIsPaywall: props.metadata?.payCategory === "plus" ? "true" : "false",
			debugPrefix: "NDI AdServing:",
			debugMode: useIsDebug(),
			elements: {
				skyContainer: null,
				wallpaperContainer: null,
				content_container: document.querySelector(".u-main-content") as HTMLElement,
				superbanner: document.getElementById("parent-superbanner") as HTMLElement,
			},
			ignoredAdSlots: <any[]>[],
			isMobile: true,
			loadedAdSlots: <any>{},
			ad_region: props.categoryData.adRegion ?? "",
			ad_state: targeting_state,
			metatag_keywords: props.metadata?.retresco?.entities?.map((entry: any) => entry?.name) || [],
			nutzertyp: userType,
			viewport: "Small",
			validSearchParams: ["ad_zone", "ad_region", "ad_state"],
			// LazyLoad Slots ueber Yieldlove
			lazyLoadAdSlots: <string[]>["m_pos3", "m_pos4", "d_sky", "d_billboard2", "d_pos2", "d_pos3", "d_pos4"],
			adSlotDefinitions: {
				m_pos1: {
					sizes: [[300, 250]],
				},
				m_pos2: {
					sizes: [
						[320, 500],
						[300, 600],
						[300, 250],
						[336, 280],
					],
				},
				m_pos3: {
					sizes: [
						[300, 600],
						[320, 500],
						[300, 250],
					],
				},
				m_pos4: {
					sizes: [
						[300, 600],
						[300, 250],
					],
				},
				m_sticky: {
					sizes: [
						[320, 50],
						[320, 75],
						[300, 50],
					],
				},
				"out-of-page": {
					sizes: ["outofpage"],
				},
				d_billboard: {
					sizes: [
						[728, 90],
						[850, 90],
						[970, 90],
						[728, 180],
						[770, 250],
						[800, 250],
						[970, 250],
						[955, 90],
					],
				},
				d_billboard2: {
					sizes: [
						[728, 90],
						[770, 250],
						[800, 250],
						[970, 250],
						[955, 90],
					],
				},
				d_sky_right: {
					sizes: [
						[120, 600],
						[160, 600],
						[200, 600],
						[300, 600],
					],
					filterSizes: true, // Slot soll nicht gefiltert werden
				},
				d_sky_right_small: {
					sizes: [
						[120, 600],
						[160, 600],
					],
					filterSizes: true, // Slot soll nicht gefiltert werden
				},
				d_sky_left: {
					sizes: [
						[120, 600],
						[160, 600],
						[200, 600],
						[300, 600],
					],
					filterSizes: true, // Slot soll nicht gefiltert werden
				},
				d_sky_left_small: {
					sizes: [
						[120, 600],
						[160, 600],
					],
					filterSizes: true, // Slot soll nicht gefiltert werden
				},
				d_rec1: {
					sizes: [[300, 250]],
				},
				d_pos1: {
					sizes: [
						[800, 450],
						[728, 90],
						[728, 180],
						[480, 360],
						[800, 250],
						[580, 360],
						[700, 990],
						[970, 250],
						[955, 90],
					],
				},
				d_pos2: {
					sizes: [
						[800, 450],
						[728, 90],
						[728, 180],
						[480, 360],
						[800, 250],
						[580, 360],
						[700, 990],
						[970, 250],
						[955, 90],
					],
				},
				d_pos3: {
					sizes: [
						[800, 450],
						[728, 90],
						[728, 180],
						[480, 360],
						[800, 250],
						[580, 360],
						[700, 990],
						[970, 250],
						[955, 90],
					],
				},
				d_pos4: {
					sizes: [
						[800, 450],
						[728, 90],
						[728, 180],
						[480, 360],
						[800, 250],
						[580, 360],
						[700, 990],
						[970, 250],
						[955, 90],
					],
				},
				adv1: {
					sizes: [
						[320, 50],
						[300, 300],
						[300, 250],
						[2, 1],
						[3, 1],
						[4, 1],
						[6, 1],
					],
				},
				adv2: {
					sizes: [
						[320, 50],
						[300, 300],
						[300, 250],
						[2, 1],
						[3, 1],
						[4, 1],
						[6, 1],
					],
				},
				adv_oben: {
					sizes: [[600, 1]],
				},
				adv_mitte: {
					sizes: [[600, 2]],
				},
				adv_unten: {
					sizes: [[600, 3]],
				},
				halfbillboard: {
					sizes: [[955, 90]],
				},
				halfpagead_unten: {
					sizes: [[300, 602]],
				},
				megabanner: {
					sizes: [[728, 91]],
				},
				imagebutton1: {
					sizes: [[300, 50]],
				},
				imagebutton2: {
					sizes: [[300, 50]],
				},
				imagebutton3: {
					sizes: [[300, 50]],
				},
				imagebutton4: {
					sizes: [[300, 50]],
				},
			},
			init: () => {
				ndiAdServing.initGoogletag();
				ndiAdServing.initYieldloveTag();
				ndiAdServing.setContentColStartValue(ndiAdServing.elements.content_container);

				if (ndiAdServing.adSlots.length === 0) {
					ndiAdServing.warn("Keine Werbeplätze gefunden. Abbruch der Verarbeitung...");
					return false;
				}

				// globale Variablen setzen -------> kann das weg?
				// if (ndiAdServing.adSlots[0].dataset?.gptUnit) {
				// 	ndiAdServing.ad_zone = ndiAdServing.adSlots[0].dataset.gptUnit; // auf manuell gesetzte Parameter prüfen

				ndiAdServing.checkURLParams();
				ndiAdServing.log("Setze Zone für Werbeausspielung: " + ndiAdServing.ad_zone);

				ndiAdServing.setViewport();
				ndiAdServing.handleAdslots();

				ndiAdServing.setCustomGoogleTagSetting();
				ndiAdServing.setKeyValues();

				ndiAdServing.setSuperbannerWidth();
				ndiAdServing.cleanUp(); // Konfiguration abschliessen und Slots mit den vorhandenen Informationen versorgen

				if (ndiAdServing.debugMode === true) {
					console.groupCollapsed("Targeting Parameters");
					console.log(
						"category:",
						ndiAdServing.category,
						" (other|article|overview|media) -- default = other, article, page = overview",
					);
					console.log("userType: ", ndiAdServing.nutzertyp);
					console.log("audience: ", ndiAdServing.audience);
					console.log("articleID: ", ndiAdServing.articleId);
					console.log("articleType: ", ndiAdServing.articleType);
					console.log("contentIsPaywall: ", ndiAdServing.contentIsPaywall);
					console.log("region: ", ndiAdServing.ad_region);
					console.groupEnd();
				}
			},
			/**
			 * Initialisiert das googletag-Objekt. Dokumentation findet sich hier: https://developers.google.com/publisher-tag/reference
			 *
			 */
			initGoogletag: () => {
				window.googletag = window.googletag || { cmd: [] }; // Google-Library initialisieren
			},
			initYieldloveTag: () => {
				window.yieldlove_cmd = window.yieldlove_cmd || []; // Yieldlove-Library initialisieren
			},
			setKeyValues: () => {
				window.googletag.cmd.push(function () {
					// setzt die Region der jeweiligen Seite
					window.googletag.pubads().setTargeting("region", ndiAdServing.ad_region); // setzt die Gemeinde der jeweiligen Seite, sofern vorhanden
					ndiAdServing.category ? window.googletag.pubads().setTargeting("category", ndiAdServing.category) : null;
					if (ndiAdServing.metatag_keywords.length > 0) {
						if (ndiAdServing.metatag_keywords.indexOf("Ukraine") !== -1) {
							window.googletag.pubads().setTargeting("ukrainekonflikt", "true");
						}
					}
					if (props.metadata?.adClient) {
						window.googletag.pubads().setTargeting("werbekunde", props.metadata.adClient);
					}
					window.googletag.pubads().setTargeting("nutzertyp", ndiAdServing.nutzertyp);
					ndiAdServing.audience ? window.googletag.pubads().setTargeting("audience", ndiAdServing.audience) : null;
					ndiAdServing.articleId ? window.googletag.pubads().setTargeting("articleId", `${ndiAdServing.articleId}`) : null;
					window.googletag.pubads().setTargeting("articleType", ndiAdServing.articleType);
					window.googletag.pubads().setTargeting("contentIsPaywall", ndiAdServing.contentIsPaywall);
				});
			},
			getCurrentViewportCategory: (): string => {
				if (ndiAdServing.clientWidth < ndiAdServing.breakpoints[0]) {
					return "Small";
				} else {
					return "Large";
				}
			},
			log: (...message: any) => {
				if (ndiAdServing.debugMode === true) {
					// eslint-disable-next-line no-console
					console.log(ndiAdServing.debugPrefix, ...message);
				}
			},
			warn: (...message: any) => {
				if (ndiAdServing.debugMode === true) {
					// eslint-disable-next-line no-console
					console.log(ndiAdServing.debugPrefix, ...message);
				}
			},
			handleAdslots: () => {
				if (ndiAdServing.isInterstitialAllowed()) {
					window.googletag.cmd.push(() => {
						const ad_zone =
							ndiAdServing.ad_zone === "testbeispiele"
								? "testbeispiele/interstitial"
								: `${ndiAdServing.admanager_tenant_path}_interstitial`;
						const ad_unit_path = `/${ndiAdServing.admanager_network_code}/${ndiAdServing.admanager_tenant_path}/${ad_zone}`;
						ndiAdServing.log("interstitial ad slot", ad_unit_path);
						ndiAdServing.loadedAdSlots["interstitial"] = window.googletag.defineOutOfPageSlot(
							ad_unit_path,
							window.googletag.enums.OutOfPageFormat.INTERSTITIAL,
						);

						if (ndiAdServing.loadedAdSlots["interstitial"]) {
							ndiAdServing.log("interstitial slot defined, and displayed");
							ndiAdServing.loadedAdSlots["interstitial"].addService(window.googletag.pubads());
							window.googletag.display(ndiAdServing.loadedAdSlots["interstitial"]);
						} else {
							ndiAdServing.log("interstitial ad slot not defined");
						}
					});
				}

				ndiAdServing.adSlots.forEach(function (element) {
					let slot_name: string | boolean = ndiAdServing.getRealSlotname(element);

					if (typeof slot_name === "string" && element.id) {
						try {
							if (Object.prototype.hasOwnProperty.call(ndiAdServing.loadedAdSlots, slot_name)) {
								ndiAdServing.log("Slot wurde bereits registriert. Überspringe diesen Durchlauf!");
							} else {
								const filteredSizes = ndiAdServing.getValidSlotSizes(element, slot_name);
								if (slot_name.startsWith("d_sky")) {
									ndiAdServing.log("Checking correct slot_name for slot: " + slot_name);
									slot_name = ndiAdServing.getCorrectSkySlot(slot_name, filteredSizes);
									ndiAdServing.log("New slot_name is: " + slot_name);
								}
								const adunit_path =
									`/${ndiAdServing.admanager_network_code}/${ndiAdServing.admanager_tenant_path}/${ndiAdServing.ad_zone}/` +
									slot_name;
								ndiAdServing.adSlotDefinitions[slot_name].filteredSizes = filteredSizes;
								ndiAdServing.loadedAdSlots[slot_name] = "running"; // Der Slotname wird registriert, um eine erneute Verarbeitung zu verhindern

								window.yieldlove_cmd.push(function () {
									window.googletag.cmd.push(function () {
										ndiAdServing.log(
											"Slot Name:" +
												slot_name +
												", Processing id: " +
												element.id +
												", Path: " +
												adunit_path +
												", Sizes: " +
												JSON.stringify(filteredSizes),
										);
										ndiAdServing.loadedAdSlots[slot_name] = window.googletag
											.defineSlot(adunit_path, filteredSizes, element.id)
											.addService(window.googletag.pubads());
										window.googletag.display(element.id);
									});

									if (!ndiAdServing.lazyLoadAdSlots.includes(slot_name)) {
										ndiAdServing.log("Yieldlove startAuction on : '" + adunit_path + "'");
										window.YLHH.bidder.startAuction(adunit_path);
									} else {
										let parentAdDiv = element?.id;
										if (typeof parentAdDiv === "undefined") {
											ndiAdServing.log("LazyLoad on element error (parentAdDiv not found)", slot_name);
											if (slot_name !== "undefined") {
												parentAdDiv = "parent-" + slot_name;
											}
										}

										ndiAdServing.log(
											"LazyLoad on element: '" + parentAdDiv + "', adunit_path: '" + adunit_path + "'",
											document.getElementById(parentAdDiv),
										);
										window.YLHH.utils.lazyLoad(parentAdDiv, function () {
											window.YLHH.bidder.startAuction(adunit_path);
										});
									}
								});
							}
						} catch (exception: any) {
							ndiAdServing.warn("Fehler beim registrieren und laden der Slots! " + exception.message);
						}
					} else {
						ndiAdServing.log("Kein Slot für diesen viewport gefunden..." + element.id);
						ndiAdServing.ignoredAdSlots.push(element);
					}

					ndiAdServing.log("---------------------------------------------");
				});
			},
			setCustomGoogleTagSetting: () => {
				window.googletag.cmd.push(function () {
					window.googletag.pubads().addEventListener("slotRenderEnded", function (event: any) {
						let slotId = event.slot.getSlotElementId();

						// Record details of the rendered ad.
						let details = {
							"Advertiser ID": event.advertiserId,
							"Campaign ID": event.campaignId,
							"Creative ID": event.creativeId,
							"Is empty?": event.isEmpty,
							"Line Item ID": event.lineItemId,
							Size: event?.size?.join("x"),
							"Source Agnostic Creative ID": event.sourceAgnosticCreativeId,
							"Source Agnostic Line Item ID": event.sourceAgnosticLineItemId,
						};

						ndiAdServing.log("Slot has finished rendering.", slotId, details);
						ndiAdServing.handleAdSlotDone(event);
					});

					window.googletag.pubads().disableInitialLoad();
					window.googletag.pubads().enableSingleRequest(); // Ein Request für alle Werbemittelanfragen
					window.googletag.enableServices(); // Aktivierung des Services
				});
			},
			/**
			 * Ermöglicht es dem Nutzer per URL verschiedene Parameter zu setzen. Die Liste der gültigen Parameter findet man in der Variablen 'validSearchParams'
			 */
			checkURLParams: () => {
				let searchParams: any = {};

				if (typeof URLSearchParams === "undefined" && document.location.search.indexOf("metatag") > 0) {
					ndiAdServing.warn("Keine manuelle Parameterauswahl möglich... :( Vielleicht mit einem anderen Browser versuchen?");
				} else {
					try {
						searchParams = currentUrl?.searchParams;

						ndiAdServing.validSearchParams.forEach(function (param) {
							if (searchParams.has(param)) {
								ndiAdServing[param] = searchParams.get(param);
								ndiAdServing.log(param + " per URL-Parameter gesetzt: " + ndiAdServing[param]);
							}
						});
					} catch (exception: any) {
						ndiAdServing.log(exception.message);
					}
				}
			},
			// Entfernt alle nicht benötigten AdSlots von der Seite
			cleanUp: () => {
				ndiAdServing.ignoredAdSlots.forEach(function (element: any) {
					ndiAdServing.log("Verarbeite den nicht verwendeten Slot: " + element.id);
					let container = document.querySelector("#parent-" + element.id);
					ndiAdServing.log("Verstecke Element: " + container?.id);
					container?.classList.add("d-none");
				});
			},
			// prüft, ob es sich um eine stickyAd handelt und entfernt die Werbeauszeichnung
			checkForStickyAd: (slot: any) => {
				const slotId = slot.getSlotElementId();
				const slotElement = document.getElementById(slotId) as HTMLElement;
				if (slotElement === null) {
					ndiAdServing.log("No Element with id", slotId, "found when looking for sticky-ad!");
				}
				if (slot === ndiAdServing.loadedAdSlots["m_sticky"] && slotElement !== null) {
					//TODO: Anzeigenkennung muss versteckt werden
					const adServerNode = slotElement.querySelector("div") as HTMLElement;
					const stickyBtn = document.createElement("button");
					stickyBtn.className = "close-sticky-ad";
					stickyBtn.onclick = function () {
						ndiAdServing.log("remove sticky from site");
						window.googletag.pubads().clear([slot]);
					};
					const style = document.createElement("style");
					const textNode = document.createTextNode(
						".close-sticky-ad { position: absolute; top: -28px; visibility: visible; width: 28px; height: 28px; right: 0; background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0nMTMnIGhlaWdodD0nMTMnIHZpZXdCb3g9JzM0MSA4IDEzIDEzJyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnPgogICAgPHBhdGggZmlsbD0nJTIzNEY0RjRGJyBkPSdNMzU0IDkuMzFMMzUyLjY5IDhsLTUuMTkgNS4xOUwzNDIuMzEgOCAzNDEgOS4zMWw1LjE5IDUuMTktNS4xOSA1LjE5IDEuMzEgMS4zMSA1LjE5LTUuMTkgNS4xOSA1LjE5IDEuMzEtMS4zMS01LjE5LTUuMTl6JyBmaWxsLXJ1bGU9J2V2ZW5vZGQnLz4KPC9zdmc+'); background-size: 13px 13px; background-position: 8px; background-color: #fff; background-repeat: no-repeat; box-shadow: 0px 0px 3px 0 rgba(0,0,0,0.2); border: none; border-radius: 3px 3px 0 0; } #" +
							slotId +
							" div {position: fixed; bottom: 0; z-index: 999; left: 50%; transform: translateX(-50%);}",
					);
					style.appendChild(textNode);
					//append to Metatag created Container -> AdserverNode
					adServerNode?.appendChild(style);
					adServerNode?.appendChild(stickyBtn);

					// "Anzeige" - Text fuer Sticky Ad verstecken
					const adLabel = slotElement?.parentElement?.querySelector(".yieldlove-ad-label") as HTMLElement; // AdLAbel suchen
					if (adLabel !== null) {
						adLabel?.classList.add("yieldlove-display-none"); // Wenn gefunden dann bitte das AdLabel ausblenden.
					}
				}
			},
			/**
			 * Prüft, ob eine posterad ausgespielt wird. Falls das der Fall ist, wird die sticky-Klasse gesetzt.
			 * @param {*} slot
			 */
			checkForPosterad: (slot: any) => {
				const adUnitPath = slot.getAdUnitPath();
				const possiblePosteradSlots = ["m_pos2", "m_pos3"];
				const isPosterAdSlot = possiblePosteradSlots.some((element) => {
					return adUnitPath.includes(element);
				});
				if (isPosterAdSlot) {
					const slotElement = document.getElementById(slot.getSlotElementId());
					const slotSizes = slotElement?.getBoundingClientRect();
					ndiAdServing.log("Slot slot.getSlotElementId() has width: " + slotSizes?.width + "and height: " + slotSizes?.height);
					const isPosterAd = slotSizes !== undefined && slotSizes.height >= 500;
					if (slotElement !== null && !isPosterAd) {
						ndiAdServing.log("Making " + slot.getSlotElementId() + " sticky - no posterad");
						slotElement.classList.add("sticky");
					}
				}
			},
			handleAdSlotDone: (event: any) => {
				ndiAdServing.checkForStickyAd(event.slot);
				ndiAdServing.checkForPosterad(event.slot);
				ndiAdServing.log("Verarbeite Slot:", event.slot.getSlotElementId(), event.slot);

				if (event.isEmpty) {
					ndiAdServing.hideEmptySlot(event.slot);
				}

				// Prüfen, ob es ein Wallpaper ist
				if (event.slot === ndiAdServing.loadedAdSlots["d_billboard"]) {
					ndiAdServing.elements.wallpaperContainer = document.querySelector("div[id*=Wallpaper]") as HTMLElement;

					if (ndiAdServing.elements.wallpaperContainer !== null) {
						ndiAdServing.log("######## Wallpaper setting is handled by NDI ########");
						// Ermitteln des Sky-Containers, hier besser über Objekt, da selektoren zu unterschiedlich
						ndiAdServing.elements.skyContainer = event.slot;
						ndiAdServing.setSkyWidth();
						ndiAdServing.handleWallpaper();
					}
				}
			},
			handleWallpaper: () => {
				ndiAdServing.setSuperbannerWidth();
				ndiAdServing.setBillboardPosition();
			},
			/**
			 * Ermittelt den Slot-Namen eines DOM-Elements. Dabei wird auch das Namens-Mapping in Betracht gezogen.
			 * Gibt den neuen Slot-Namen oder false zurück, falls kein Name ermittelt werden konnte.
			 *
			 * @param element
			 * @return {boolean|*}
			 */
			getRealSlotname: (element: any): string | boolean => {
				if (element.dataset && element.dataset["adUnit" + ndiAdServing.viewport]) {
					let slot_name = element.dataset["adUnit" + ndiAdServing.viewport];
					ndiAdServing.log("Slotname für nächstes Element:", slot_name);
					return slot_name;
				} else {
					ndiAdServing.warn("Kein Slotname für dieses Element gefunden!", element);
					return false;
				}
			},
			/**
			 * Ermittelt die Größe eines HTMLElements und gibt sie zurück.
			 * Wenn keine Größe ermittelt werden kann, wird eine Default-Größe zurück gegeben.
			 *
			 * @param slotContainer {HTMLElement}
			 * @return {DOMRect|Object}
			 */
			getContainerSize: (slotContainer: any): any => {
				let containerSizes;
				try {
					containerSizes = slotContainer.getBoundingClientRect();
				} catch (e) {
					containerSizes = { width: 300, height: 250 }; // Default Größen
				}

				return containerSizes;
			},
			setSuperbannerWidth: () => {
				if (ndiAdServing.elements.superbanner === null) {
					ndiAdServing.log("No superbanner found. Skipping");
					return false;
				}

				ndiAdServing.elements.superbanner.style.width =
					ndiAdServing.getContainerSize(ndiAdServing.elements.content_container).width + "px";
				ndiAdServing.log("Setting new superbanner width: " + ndiAdServing.elements.superbanner.style.width);
				ndiAdServing.setBillboardPosition();
			},
			setSkyWidth: (wallpaperSky: any = null) => {
				const skyContainer = wallpaperSky || ndiAdServing.elements.skyContainer;

				if (skyContainer === null) {
					ndiAdServing.log("No sky Container found. Skipping");
					return false;
				}

				if (window.slider) {
					ndiAdServing.log("Updating Swiper");
					window.slider.update();
				}

				if (window.subNavigation) {
					ndiAdServing.log("Updating subNavigation");
					window.subNavigation.resize();
				}
			},
			// TODO: Warten auf Rueckmeldung von Nick ob der Billboard mal nicht Mittig ausgespielt wird. Wenn Nick "ja" sagt muss man das nochmal prüfen. Auch bei der Funktion "handleAdSlotDone" beachten
			setBillboardPosition: () => {
				const sizes = ndiAdServing.getContainerSize(ndiAdServing.elements.content_container);
				const superbannerContainer = document.querySelector("div[id*=Wallpaper]") as HTMLElement;

				if (sizes && superbannerContainer) {
					const newLeft = (sizes.width - superbannerContainer.getBoundingClientRect().width) / 2;
					ndiAdServing.log("Setting new skyscraper position: " + newLeft + "px");
					superbannerContainer.style.left = newLeft + "px";
				}

				let skyContainer = document.querySelector("[id*=d_sky]") as HTMLElement;
				if (sizes && skyContainer !== null) {
					skyContainer = skyContainer.parentElement as HTMLElement;
					const skyContainerSizes = skyContainer?.getBoundingClientRect();
					if (Math.ceil(sizes.right + 15) >= Math.ceil(skyContainerSizes.left)) {
						// Sky ragt in Content-Col // 15px hinzu wegen padding
						const newSkyLeftPos = Math.ceil(sizes.right) + 2; // 2px addieren
						skyContainer.style.left = newSkyLeftPos + "px";
					} else {
						if (skyContainer?.style?.left) {
							skyContainer.style.left = "auto";
						}
					}
				}
			},
			/**
			 * Sucht anhand der Container-Breite die verfügbaren Größen heraus.
			 * Bei Elementen, die keinen Container haben, werden die registrierten Größen (adSlotDefinition) zurück gegeben.
			 *
			 * @param {Object} slot Ad-Slot, dessen Größe ermittelt werden soll
			 * @param {string} slotName Name des AdSlots (aus den adSlotDefinitions)
			 *
			 * @returns Array alle zu filternden Größen
			 * */
			getValidSlotSizes: (slot: any, slotName: string) => {
				if (!Object.prototype.hasOwnProperty.call(ndiAdServing.adSlotDefinitions, slotName)) {
					return false;
				}
				if (ndiAdServing.adSlotDefinitions[slotName].filterSizes === false) {
					return ndiAdServing.adSlotDefinitions[slotName].sizes;
				}
				const slotContainer = slot.parentElement;
				const contentSizes = ndiAdServing.getContainerSize(slotContainer);
				const slotSizes = ndiAdServing.adSlotDefinitions[slotName].sizes;
				return ndiAdServing.filterSlotSizes(contentSizes.width, slotSizes);
			},
			/**
			 * Setzt den Viewport auf die richtige größe
			 *
			 * */
			setViewport: () => {
				ndiAdServing.viewport = ndiAdServing.getCurrentViewportCategory();
				ndiAdServing.log("Setze den viewport auf: " + ndiAdServing.viewport);

				ndiAdServing.isMobile = ndiAdServing.clientWidth < ndiAdServing.breakpoints[0];
				ndiAdServing.log("isMobile: " + ndiAdServing.isMobile);
			},

			isInterstitialAllowed: (): boolean | string => {
				const isAllowed = ndiAdServing.category === "article" && ndiAdServing.nutzertyp === "anonymous" && !useIsApp();
				ndiAdServing.log("isInterstitialAllowed", isAllowed);
				ndiAdServing.log("isArticle", ndiAdServing.category === "article");
				ndiAdServing.log("isAnonymous", ndiAdServing.nutzertyp === "anonymous");
				ndiAdServing.log("isApp", useIsApp());

				return isAllowed;
			},
			getCorrectSkySlot: (slot_name: string, sizes: any[]): string => {
				if (sizes.filter((entry) => entry[0] > 200).length === 0) {
					return slot_name + "_small";
				} else {
					return slot_name;
				}
			},
			/**
			 * Filtert ein array anhand der übergebenen Breite. Größen, die nicht in die Breite passen, werden entfernt.
			 * @param width
			 * @param slotSizes
			 */
			filterSlotSizes: (width: number, slotSizes: any[]) => {
				const validSizes = [];
				for (let i = 0; i < slotSizes.length; i++) {
					if (slotSizes[i][0] <= width) {
						validSizes.push(slotSizes[i]);
					}
				}
				return validSizes;
			},
			/**
			 * Nimmt den Top-Wert des übergebenen Elements und trägt ihn in die CSS-Variable "content-col-start" ein
			 * @param element
			 */
			setContentColStartValue: (element: HTMLElement) => {
				if (!element) return false;
				return document.documentElement.style.setProperty("--content-col-start", element.offsetTop + "px");
			},
			hideEmptySlot: (slot: any) => {
				ndiAdServing.log("Verstecke den leeren Slot " + slot.getAdUnitPath());
				try {
					const container = document.querySelector("#parent-" + slot.getSlotElementId());
					if (container) {
						ndiAdServing.log("Slot " + slot.getAdUnitPath() + " wurde versteckt");
						container.classList.add("hidden");
					}
				} catch (e) {
					console.warn("Container konnte nicht ausgeblendet werden. Gesucht wurde: #parent-" + slot.getSlotElementId());
				}
			},
		};

		if (showAds.getShowAds().value === true) {
			ndiAdServing.init();
			window.addEventListener("resize", ndiAdServing.setSuperbannerWidth);
			window.ndiAdServing = ndiAdServing;
			useTransmaticoSmartAds();
		}
	});

	useHead({
		script: [
			{
				children: `var ad_targeting_region = '${props.categoryData.adRegion}'; var ad_targeting_bundesland = '${targeting_state}'; var ad_targeting_keywords = [${props.metadata?.retresco?.entities.length ? props.metadata?.retresco?.entities?.map((entry: any) => '"' + entry?.name + '"') : ""}]`,
			},
		],
	});
</script>
