/**
 * Define different content types used in all the applications microservices
 */
export enum ContentTypes {
	ARTICLE_BADEN_WUERTTEMBERG = "article-bawu",
	ARTICLE_BRANDENBURG = "article-brandenburg",
	ARTICLE_DEFAULT = "article",
	ARTICLE_TAGESMEDIEN = "article",
	AUTHOR = "author",
	PAGE_SWP = "page-swp",
	PAGE_LR = "page-lr",
	PAGE_MOZ = "page-moz",
	NEWSLETTER = "newsletter-value",
	REDIRECT = "redirect",
	RESSORT = "ressort",
	MENU = "menu",
	CATEGORY_TREE = "category-tree",
}

/**
 * Define which content types are considered to be real articles
 */
export const ArticleContentTypes = [
	ContentTypes.ARTICLE_BADEN_WUERTTEMBERG,
	ContentTypes.ARTICLE_BRANDENBURG,
	ContentTypes.ARTICLE_DEFAULT,
	ContentTypes.ARTICLE_TAGESMEDIEN,
];

/**
 * Define which content types are considered to be authors
 * E.g. freelancers might also be considered authors even though they are not of content type author
 */
export const AuthorContentTypes = [ContentTypes.AUTHOR];

/**
 * Define which content types are considered to be pages
 */
export const PageContentTypes = [ContentTypes.PAGE_SWP, ContentTypes.PAGE_MOZ, ContentTypes.PAGE_LR];

/**
 * Defines all content types used as newsletter
 */
export const NewsletterContentTypes = [ContentTypes.NEWSLETTER];
