export default function useTransmaticoSmartAds() {
	// SMART ADS Einbindung - ohne gtm
	// Muss unabhängig der Transmatico-Komponente geladen werden.
	function triggerTransmaticoSmartAds() {
		let transmaticoSmartAdsScript: string[] = [];

		if (usePublication() === "swp") {
			transmaticoSmartAdsScript = [
				"https://d.smartico.one/get/hs/i3s8af8qasdg245frnuvw4gy0v4bqs2f66iwbnfd",
				"https://d.smartico.one/get/hs/6ek6ljudru8a3umtrr77duyyfkgi2b6y16w4gd4y",
				"https://d.smartico.one/get/hs/f9dt2azkd7mzd7k91tcmp0mfzlib5k377ghormq0", // CSS
			];
		} else if (usePublication() === "moz") {
			transmaticoSmartAdsScript = [
				"https://d.smartico.one/get/hs/i3s8af8qasdg245frnuvw4gy0v4bqs2f66iwbnfd",
				"https://d.smartico.one/get/hs/6ek6ljudru8a3umtrr77duyyfkgi2b6y16w4gd4y",
				"https://d.smartico.one/get/hs/f9dt2azkd7mzd7k91tcmp0mfzlib5k377ghormq0", // CSS
			];
		} else if (usePublication() === "lr") {
			transmaticoSmartAdsScript = [
				"https://d.smartico.one/get/hs/i3s8af8qasdg245frnuvw4gy0v4bqs2f66iwbnfd",
				"https://d.smartico.one/get/hs/6ek6ljudru8a3umtrr77duyyfkgi2b6y16w4gd4y",
				"https://d.smartico.one/get/hs/f9dt2azkd7mzd7k91tcmp0mfzlib5k377ghormq0", // CSS
			];
		}

		useHead({
			script: [
				{ src: transmaticoSmartAdsScript[0], async: true },
				{ src: transmaticoSmartAdsScript[1], async: true },
			],
			link: [
				{
					rel: "stylesheet",
					href: transmaticoSmartAdsScript[2],
				},
			],
		});
	}

	// Wenn von NDI AdScript aufgerufen, dann ist folgende globale Variable noch nicht definiert.
	// Wird daher von einem der beiden nachfolgenden Event Handlern ausgelöst.
	if (window.isCMPLoaded) {
		triggerTransmaticoSmartAds();
	} else {
		document.addEventListener("cmp.consentDataSet", triggerTransmaticoSmartAds);
	}
	document.addEventListener("cmp.consentDataUpdate", triggerTransmaticoSmartAds);
}
